import classes from './Modal.module.css';
import { createPortal} from 'react-dom';
import { useEffect } from 'react';

const BackDrop = (props) => {
    return <div className={classes.backdrop} onClick={props.onhide}/>;
};

const Overlay = (props) => {
    return (
        <div className={classes.overlay}>
            <div className={classes.content}>{props.children}</div>
        </div>
    )
};

const portalNode = document.getElementById('overlays');

const Modal = (props) => {
    useEffect(() => {
        // Add class when modal mounts
        document.body.classList.add('no_scroll');
        
        // Remove class when modal unmounts
        return () => {
            document.body.classList.remove('no_scroll');
        };
    }, []);

    return (
        <>
            {createPortal(<BackDrop onhide={props.hideProduct} />, portalNode)}
            {createPortal(<Overlay>{props.children}</Overlay>, portalNode)}
        </>
    )
};

export default Modal;