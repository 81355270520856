import { useState, useEffect } from 'react';
import { useLoaderData } from 'react-router-dom'; 

import RepairPrices from '../components/repairprices/RepairPrices';
import HighSpeeds from '../components/forsale/HighSpeeds';
import classes from '../components/forsale/HighSpeeds.module.css';
import LowSpeeds from '../components/forsale/LowSpeeds';
import ProductDetails from '../components/product_details/ProductDetails';

import products from '../static/products';

const Services = () => {
    const [detailsShown, setDetailsShown] = useState(false);
    const [currentProduct, setCurrentProduct] = useState('');
    const [productDetails, setProductDetails] = useState(null);
    const [hsProductInfo, setHsProductInfo] = useState(null);
    const [lsProductInfo, setLsProductInfo] = useState(null);

    const response = useLoaderData();
    console.log(response);

    // 'info' is an array from the static product data that contains two elements: product id and a boolean that is true for highspeeds and false for lowspeeds lol. (Change this!)
    const showDetailsHandler = (info) => {
        setDetailsShown(true);
        setCurrentProduct(info);
        setProductDetails(response);
    };
    
    const hideDetailsHandler = () => {
        setDetailsShown(false);
    };

    const high_speeds = products.highSpeeds;
    const low_speeds = products.lowSpeeds;

    useEffect(() => {
        setHsProductInfo(response.hs_thumbnail);
        setLsProductInfo(response.ls_thumbnail);
    }, []);

    return (
        <>
            {detailsShown && <ProductDetails onVanish={hideDetailsHandler} current={currentProduct} details={productDetails} />}
            <RepairPrices />
            <h1 className={classes.for_sale_title}>For Sale</h1>
            <HighSpeeds hsProductData={high_speeds} product_data={hsProductInfo} onAppear={showDetailsHandler}/>
            <LowSpeeds lsProductData={low_speeds} product_data={lsProductInfo} onAppear={showDetailsHandler}/>
        </>
    )
};

const loader = async () => {
    try {
        const url = 'https://fidelitydental-restore-default-rtdb.firebaseio.com/.json';
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching product data', error);
    }
}

export { loader };
export default Services;