import { useState } from 'react';
import { ImCancelCircle } from "react-icons/im";

import OrderForm from './OrderForm';
import DetailList from './DetailList';
import Modal from '../UI/Modal';
import classes from './ProductDetails.module.css';

    

const ProductDetails = (props) => {
    const [imgExpanded, setImgExpanded] = useState(false);
    const [formView, setFormView] = useState(false);

    const viewFormHandler = () => {
        setFormView(true);
    };

    const hideFormHandler = () => {
        setFormView(false);
    };

    const expandHandler = () => {
        setImgExpanded(true);
    };

    const colapseHandler = () => {
        setImgExpanded(false);
    };

    // Accessing specific product data.
    const productData = props.details;
    const productGroup = props.current[1];
    const groupData = productGroup ? productData.high_speeds : productData.low_speeds;
    const currentID = props.current[0];
    const proDetails = groupData[currentID];
    
    const images_links = proDetails.images;
    let images = [];
    for (const img in images_links) {
        images.push(images_links[img]);
    }

    const [activeImg, setActiveImg] = useState(images[0]);

    const imgSwitchHandler = () => {
        setActiveImg();
    };

    const renderImages = (i) => {
        if (i === activeImg) {
            return <img src={i} alt={`Image of ${proDetails.name}`} className={classes.selected_img} onClick={() => setActiveImg(i)}/>;
        } else {
            return <img src={i} alt={`Image of ${proDetails.name}`} className={classes.side_img} onClick={() => setActiveImg(i)}/>;
        }
    };

    // Conditionally rendering className based on whether image has been clicked.
    const mainImage = (!imgExpanded ? <img src={activeImg} alt={`Image of ${proDetails.name}`} className={classes.active_img} onClick={expandHandler}/> : <img src={activeImg} alt={`Image of ${proDetails.name}`} className={classes.expanded_img} onClick={colapseHandler}/>)

    return (    
        <Modal hideProduct={props.onVanish}>
            {/* This should be its own component */}
            <div className={classes.img_cache}>
                {mainImage}
                <div className={classes.inactive_imgs}>
                    {images.map((img) => { 
                        return renderImages(img);
                    })}
                </div>
            </div>
            <div className={classes.details}>
                <div className={classes.top}>
                    { productGroup ? <div className={classes.product_group}>High Speed</div> : <div className={classes.product_group}>Low Speed</div> }
                    <ImCancelCircle className={classes.close} onClick={props.onVanish}/>
                </div>
                { 
                !formView ? <>
                    <div className={classes.dets_header}>
                        <div className={classes.product_name}>{proDetails.name}</div>
                        {proDetails.sn !== 'C08773' ? <div className={classes.price}>Fidelity Price: ${proDetails.sale_price}</div> : <div className={classes.price}>{proDetails.sale_price}</div>}
                    </div>
                    <div className={classes.list_section}>
                        <div className={classes.details_list}>Details:</div>
                        <DetailList dets={proDetails}/>
                    </div> 
                    <div className={classes.buttons} onClick={viewFormHandler}>
                        <button className={classes.order_button}>Message</button>
                    </div>
                </>
                : <OrderForm cancelForm={hideFormHandler} dets={proDetails} /> 
                }
            </div>
        </Modal>
    );
}

export default ProductDetails;